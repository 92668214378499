import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import KeyStatistic from '../components/keyStatistic';
import CommunitiesBanner from '../components/CommunitiesBanner';
import HeaderLogo from '../components/HeaderLogo';
import HeaderTitle from '../components/HeaderTitle';
import Footer from '../components/Footer';
import NextEvents from '../components/NextEvents';
import { TakeawaysWrapper } from './followUpTemplate';
import '../css/followUpTemplate.css';

const handleResize = () => {
  // set variables
  const headers = document.querySelectorAll('.flex__header');
  const mustReads = document.querySelectorAll('.flex__must-read');
  const keyTakeaways = document.querySelectorAll('.flex__key-takeaway');
  const quotes = document.querySelectorAll('.flex__quote');

  /*
   * @function sameHeight: calculate max-height and set the value to the elements
   * @params {arr} array of html elements
   * side effect: set the height on the elements in the array with the maximum height
   */
  function sameHeight(arr) {
    let maxHeight = 0;
    const width = window.innerWidth;

    // reset the height:
    arr.forEach((element) => {
      element.style.height = 'inherit';
      return null;
    });

    // if window inner width is more than 480px set the same max width to the elements
    // otherwise for mobile is already reset to inherit
    if (width > 480) {
      // calculate the maxHeight of the elements
      arr.forEach((element) => {
        if (element.offsetHeight > maxHeight) {
          maxHeight = element.offsetHeight;
        }
        return null;
      });

      // set the maxHeight to every elements
      arr.forEach((element) => {
        element.style.height = `${maxHeight}px`;
        return null;
      });
    }
  }

  // function calls with the 4 different array
  sameHeight(headers);
  sameHeight(mustReads);
  sameHeight(keyTakeaways);
  sameHeight(quotes);
};

export default function wpwTemplate(props) {
  useEffect(() => {
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const {
    data,
    pageContext, // this prop will be injected by the GraphQL query below.
  } = props;

  const wpcTakeaways = data.allContentfulTakeaway.edges.filter(item => item.node.event.permalink === 'wpc');
  const iclfTakeaways = data.allContentfulTakeaway.edges.filter(item => item.node.event.permalink === 'iclf');
  const sddTakeaways = data.allContentfulTakeaway.edges.filter(item => item.node.event.permalink === 'strategy-deep-dive');

  return (
    <>
      <Helmet>
        <title>World Procurement Week follow-up</title>
      </Helmet>
      <HeaderLogo />
      <HeaderTitle title='What you missed out on' logo={data.contentfulAsset.fluid.src} />
      <TakeawaysWrapper>
        <div className="each-event-header-wrap">
          <h2>World Procurement Congress</h2>
          {/* <img className="wpw-img"src={data.wpc.logo.fluid.src} /> */}
        </div>
        <div className="wpw-wrap">
          {wpcTakeaways.map((takeaway) => {
            const { node } = takeaway;
            return (
              <div className='flex-column' key={node.id}>
                <div className='flex__header'>
                  <h2>{node.title}</h2>
                  {/* <div className="img-container">
                    <img
                      className='logo-thumbnail'
                      src={node.event.logo.fluid.src}
                      alt={node.event.logo.title}
                    />
                  </div> */}
                </div>
                <div className='flex__must-read'>
                  <p className='must-read'>Must read PL resource</p>
                  {pageContext.isMember ? (
                    <>
                      <div className='image'>
                        <img
                          className='node__thumbnail'
                          src={node.thumbnail.fluid.src}
                          alt={node.thumbnail.fluid.title}
                        />
                      </div>
                      <h3>{node.resourceTitle}</h3>
                      <p className='text-must-read'>{node.resourceDescription}</p>
                    </>
                  ) : (
                    <>
                      <div className='image'>
                        <img
                          className='node__thumbnail'
                          src={node.nonMemberResourceThumbnail.fluid.src}
                          alt={node.nonMemberResourceThumbnail.fluid.title}
                        />
                      </div>
                      <h3>{node.nonMemberResourceTitle}</h3>
                      <p className='text-must-read'>{node.nonMemberResourceDescription}</p>
                    </>
                  )}
                  <a
                    className='read-more'
                    href={
                      pageContext.isMember
                        ? node.memberLink
                        : node.nonMemberLink
                    }
                  >
                    READ MORE
                  </a>
                </div>
                <div className='flex__key-takeaway'>
                  <p className='key-takeaway'>Key takeaways</p>
                  {documentToReactComponents(node.keyTakeaways.json)}
                </div>
                <div className='flex__quote'>
                  <KeyStatistic statistic={node.keyStatistic.json} quoteSource={node.quoteSource} />
                </div>
              </div>
            );
          })};
        </div>
        <div className="each-event-header-wrap">
          <h2>Indirect Category Leadership Forum</h2>
          {/* <img className="wpw-img" src={data.iclf.logo.fluid.src} /> */}
        </div>
        <div className="wpw-wrap">
          {iclfTakeaways.map((takeaway) => {
            const { node } = takeaway;
            return (
              <div className='flex-column' key={node.id}>
                <div className='flex__header'>
                  <h2>{node.title}</h2>
                </div>
                <div className='flex__must-read'>
                  <p className='must-read'>Must read PL resource</p>
                  {pageContext.isMember ? (
                    <>
                      <div className='image'>
                      <img
                        className='node__thumbnail'
                        src={node.thumbnail.fluid.src}
                        alt={node.thumbnail.fluid.title}
                      />
                      </div>
                      <h3>{node.resourceTitle}</h3>
                      <p className='text-must-read'>{node.resourceDescription}</p>
                    </>
                  ) : (
                    <>
                      <div className='image'>
                      <img
                        className='node__thumbnail'
                        src={node.nonMemberResourceThumbnail.fluid.src}
                        alt={node.nonMemberResourceThumbnail.fluid.title}
                      />
                      </div>
                      <h3>{node.nonMemberResourceTitle}</h3>
                      <p className='text-must-read'>{node.nonMemberResourceDescription}</p>
                    </>
                  )}
                  <a
                    className='read-more'
                    href={
                      pageContext.isMember
                        ? node.memberLink
                        : node.nonMemberLink
                    }
                  >
                    READ MORE
                  </a>
                </div>
                <div className='flex__key-takeaway'>
                  <p className='key-takeaway'>Key takeaways</p>
                  {documentToReactComponents(node.keyTakeaways.json)}
                </div>
                <div className='flex__quote'>
                  <KeyStatistic statistic={node.keyStatistic.json} quoteSource={node.quoteSource} />
                </div>
              </div>
            );
          })};
        </div>
        <div className="each-event-header-wrap">
        <h2>Strategy Deep Dives</h2>
        {/* <img className="wpw-img" src={data.sdd.logo.fluid.src} /> */}
        </div>

        <div className="wpw-wrap">
          {sddTakeaways.map((takeaway) => {
            const { node } = takeaway;
            return (
              <div className='flex-column' key={node.id}>
                <div className='flex__header'>
                  <h2>{node.title}</h2>
                  {/* <div className="img-container">
                    <img
                      className='logo-thumbnail'
                      src={node.event.logo.fluid.src}
                      alt={node.event.logo.title}
                    />
                  </div> */}
                </div>
                <div className='flex__must-read'>
                  <p className='must-read'>Must read PL resource</p>
                  {pageContext.isMember ? (
                    <>
                      <div className='image'>
                      <img
                        className='node__thumbnail'
                        src={node.thumbnail.fluid.src}
                        alt={node.thumbnail.fluid.title}
                      />
                      </div>
                      <h3>{node.resourceTitle}</h3>
                      <p className='text-must-read'>{node.resourceDescription}</p>
                    </>
                  ) : (
                    <>
                      <div className='image'>
                      <img
                        className='node__thumbnail'
                        src={node.nonMemberResourceThumbnail.fluid.src}
                        alt={node.nonMemberResourceThumbnail.fluid.title}
                      />
                      </div>
                      <h3>{node.nonMemberResourceTitle}</h3>
                      <p className='text-must-read'>{node.nonMemberResourceDescription}</p>
                    </>
                  )}
                  <a
                    className='read-more'
                    href={
                      pageContext.isMember
                        ? node.memberLink
                        : node.nonMemberLink
                    }
                  >
                    READ MORE
                  </a>
                </div>
                <div className='flex__key-takeaway'>
                  <p className='key-takeaway'>Key takeaways</p>
                  {documentToReactComponents(node.keyTakeaways.json)}
                </div>
                <div className='flex__quote'>
                  <KeyStatistic statistic={node.keyStatistic.json} quoteSource={node.quoteSource} />
                </div>
              </div>
            );
          })};
        </div>
      </TakeawaysWrapper>
      {pageContext.isMember && <CommunitiesBanner />}
      <NextEvents events={data.allContentfulFutureEvents.edges} />
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query {
    allContentfulTakeaway(filter: {wpwPick: {eq: true}}) {
      edges {
        node {
          id
          title
          resourceTitle
          resourceDescription
          nonMemberResourceDescription
          nonMemberResourceTitle
          nonMemberResourceThumbnail {
            fluid(maxWidth: 500) {
              src
            }
          }
          memberLink
          nonMemberLink
          thumbnail {
            id
            title
            fluid(maxWidth: 500) {
              src
            }
          }
          keyTakeaways {
            json
          }
          keyStatistic {
            id
            json
          }
          quoteSource
          event {
            title
            permalink
            logo {
              id
              title
              fluid(maxWidth: 200) {
                src
              }
            }
          }
        }
      }
    }
    allContentfulFutureEvents(sort: { fields: startDate }) {
      edges {
        node {
          id
          eventName
          eventImage {
            file {
              url
            }
          }
          themeOne
          themeTwo
          themeThree
          description
          startDate(formatString: "DD MMMM YYYY")
          endDate(formatString: "DD MMMM YYYY")
          eventLocation
          agendaLink
          active
        }
      }
    }
    contentfulAsset(title: {eq: "WPW logo no bg"}) {
      fluid {
        src
      }
    }
    wpc: contentfulEvent(permalink: {eq: "wpc"}) {
      logo {
        fluid(maxWidth: 117) {
          src
        }
      }
    }
    sdd: contentfulEvent(permalink: {eq: "strategy-deep-dive"}) {
      logo {
        fluid(maxWidth: 117) {
          src
        }
      }
    }
    iclf: contentfulEvent(permalink: {eq: "iclf"}) {
      logo {
        fluid(maxWidth: 117) {
          src
        }
      }
    }
  }
`;

wpwTemplate.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
    ancestorOrigins: PropTypes.shape({}),
    origin: PropTypes.string,
    protocol: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    port: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.any,
    key: PropTypes.string
  }),
  pageResources: PropTypes.shape({
    page: PropTypes.shape({
      componentChunkName: PropTypes.string,
      jsonName: PropTypes.string,
      path: PropTypes.string
    })
  }),
  data: PropTypes.shape({
    allContentfulTakeaway: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            resourceTitle: PropTypes.string,
            resourceDescription: PropTypes.string,
            memberLink: PropTypes.string,
            nonMemberLink: PropTypes.string,
            thumbnail: PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string,
              fluid: PropTypes.shape({ src: PropTypes.string })
            }),
            keyTakeaways: PropTypes.shape({
              json: PropTypes.shape({
                data: PropTypes.shape({}),
                content: PropTypes.arrayOf(
                  PropTypes.shape({
                    data: PropTypes.shape({}),
                    content: PropTypes.arrayOf(
                      PropTypes.shape({
                        data: PropTypes.shape({}),
                        content: PropTypes.arrayOf(
                          PropTypes.shape({
                            data: PropTypes.shape({}),
                            content: PropTypes.arrayOf(
                              PropTypes.shape({
                                data: PropTypes.shape({}),
                                marks: PropTypes.array,
                                value: PropTypes.string,
                                nodeType: PropTypes.string
                              })
                            ),
                            nodeType: PropTypes.string
                          })
                        ),
                        nodeType: PropTypes.string
                      })
                    ),
                    nodeType: PropTypes.string
                  })
                ),
                nodeType: PropTypes.string
              })
            }),
            keyStatistic: PropTypes.shape({
              id: PropTypes.string,
              json: PropTypes.shape({
                data: PropTypes.shape({}),
                content: PropTypes.arrayOf(
                  PropTypes.shape({
                    data: PropTypes.shape({}),
                    content: PropTypes.arrayOf(
                      PropTypes.shape({
                        data: PropTypes.shape({}),
                        marks: PropTypes.arrayOf(
                          PropTypes.shape({ type: PropTypes.string })
                        ),
                        value: PropTypes.string,
                        nodeType: PropTypes.string
                      })
                    ),
                    nodeType: PropTypes.string
                  })
                ),
                nodeType: PropTypes.string
              })
            }),
            quoteSource: PropTypes.string
          })
        })
      )
    }),
    allContentfulFutureEvents: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            eventName: PropTypes.string,
            eventImage: PropTypes.shape({
              file: PropTypes.shape({ url: PropTypes.string })
            }),
            themeOne: PropTypes.string,
            themeTwo: PropTypes.string,
            themeThree: PropTypes.string,
            description: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            eventLocation: PropTypes.string,
            agendaLink: PropTypes.string,
            active: PropTypes.bool
          })
        })
      )
    })
  }),
  pageContext: PropTypes.shape({
    isCreatedByStatefulCreatePages: PropTypes.bool,
    permalink: PropTypes.string,
    isMember: PropTypes.bool,
    isCPO: PropTypes.bool
  })
};
