import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';

const Wrapper = styled.blockquote`
  max-width: 400px;
  text-align: center;
  margin: 50px auto 1.6rem;
  color: #008e7f;
  position: relative;
  padding: 1rem;
  p{
    font-family: "PT Serif" !important;
    font-size: 2.1rem;
    margin: 0
  }
  strong {
    font-size: 2.6rem;
  }
  span.quote-person {
    display: block;
    font-size: 1.2rem;
    color: #666666;
    position: relative;
    margin-top: 15px;
    font-family: "Roboto", sans-serif;
  }
  span.quote-person::before {
    display: block;
    content: "";
    width: 60px;
    height: 2px;
    background: #666;
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -30px;
  }
  div.quote::after {
    background-image: url('https://www.procurementleaders.com/AcuCustom/Sitename/Icon/Item/quote-img-v1-2019.png');
    display: block;
    width: 50px;
    height: 39px;
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -25px;
  }
`;
const KeyStatistic = ({ statistic, quoteSource }) => (
  <Wrapper>
    <div className='quote'>
      {documentToReactComponents(statistic)}
      <span className='quote-person'>{quoteSource}</span>
    </div>
  </Wrapper>
);

KeyStatistic.propTypes = {
  statistic: PropTypes.object.isRequired,
  quoteSource: PropTypes.string.isRequired
};

export default KeyStatistic;
