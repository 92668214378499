import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.footer`
  background-color: #000;
  p{
    color: #fff;
    font-size: 1.2rem;
    font-weight: 300;
  }
`;

const Inner = styled.div`
  padding: 6rem 6.7rem;
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;

  @media (max-width: 1023px){
    padding: 6rem;
  }

  @media (max-width: 799px){
    padding: 2rem;
  }

  @media (max-width: 480px){
    padding: 1rem;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Inner>
        <p>© Procurement Leaders Limited and its contributors. All rights reserved. www.procurementleaders.com <br /> Procurement Leaders Limited accepts no responsibility for advice or information contained on this site although every effort is made to ensure its accuracy. Users are advised to seek independent advice from qualified persons before acting upon any such information.
        </p>
      </Inner>
    </Wrapper>
  );
}

export default Footer;
