import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.header`
  background-color: #f4f4f4;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  .header__inner {
    padding: 6rem 6.7rem;
    width: 100%;
    max-width: 128rem;
    margin: 0 auto;
  }
  h1 {
    margin: 0;
    font-size: 4.4rem;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
`;

const Inner = styled.div`
  padding: 4rem 6.7rem;
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .image-wrapper {
    max-width: 117px;
    img {
      width: 100%;
    }
  }

  @media (max-width: 1023px) {
    padding: 4rem 6rem;
  }

  @media (max-width: 799px) {
    padding: 2rem;
  }

  @media (max-width: 480px) {
    padding: 1rem;

    .image-wrapper {
      display: none;
    }
  }
`;

function HeaderTitle(props) {
  const { title, subtitle, logo } = props;
  return (
    <Wrapper>
      <Inner>
        <h1>{title}</h1>
        {subtitle && <p className='subtitle'>{subtitle}</p>}
        <div className='image-wrapper'>
          <img src={logo} alt={title} />
        </div>
      </Inner>
    </Wrapper>
  );
}
HeaderTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  logo: PropTypes.string,
};

export default HeaderTitle;
