import React from 'react';
import styled from 'styled-components';

const InnerLogo = styled.div`
  padding: 1.6rem 6.7rem;
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;

  @media (max-width: 1023px){
    padding: 1.6rem 6rem;
  }

  @media (max-width: 799px){
    padding: 1.6rem 2rem;
  }

  @media (max-width: 480px){
    padding: 1rem;
  }
`;

const Logo = styled.div`
  background-color: #fff;
  img{
    width: 117px;
    height: auto;
    vertical-align: middle;
  }
`;

function HeaderLogo() {
  return (
    <Logo>
      <InnerLogo>
        <img alt='Procurement Leaders logo' src='https://www.procurementleaders.com/AcuCustom/Sitename/Icon/Icons/PL_Logo.svg' />
      </InnerLogo>
    </Logo>
  );
}

export default HeaderLogo;
