import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SingleEvent from './SingleEvent';

const Wrapper = styled.header`
  background-color: #f4f4f4;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  .header__inner {
    padding: 6rem 6.7rem;
    width: 100%;
    max-width: 128rem;
    margin: 0 auto;
  }
  h1 {
    margin: 0;
    font-size: 4.4rem;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
`;

const Inner = styled.div`
  padding: 6rem 6.7rem;
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;

  @media (max-width: 1023px) {
    padding: 6rem;
  }

  @media (max-width: 799px) {
    padding: 2rem;
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-content: space-between;
  flex-wrap: wrap;
`;

const NextEvents = (props) => {
  const { events } = props;
  return (
    <Wrapper>
      <Inner>
        <h2>Upcoming Events</h2>
        <p className='subtitle'>Plan ahead and join us at an upcoming event</p>

        <br />
        <br />
        <CardWrapper>
          {events
            .map((event) => {
              if (event.node.active === true) {
                return <SingleEvent key={event.node.id} event={event.node} />;
              }
              return null;
            })}
        </CardWrapper>
      </Inner>
    </Wrapper>
  );
};

NextEvents.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        eventName: PropTypes.string,
        eventImage: PropTypes.shape({
          file: PropTypes.shape({ url: PropTypes.string }),
        }),
        themeOne: PropTypes.string,
        themeTwo: PropTypes.string,
        themeThree: PropTypes.string,
        description: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        eventLocation: PropTypes.string,
        agendaLink: PropTypes.string,
        active: PropTypes.bool,
      }),
    })
  ),
};

export default NextEvents;
