import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import KeyStatistic from '../components/keyStatistic';
import HeaderLogo from '../components/HeaderLogo';
import HeaderTitle from '../components/HeaderTitle';
import Footer from '../components/Footer';
import NextEvents from '../components/NextEvents';
import CommunitiesBanner from '../components/CommunitiesBanner';
import '../css/followUpTemplate.css';

export const TakeawaysWrapper = styled.div`
  width: 100%;
  max-width: 128rem;
  margin: 0 auto 20px;
  /* padding: 6rem 6.7rem; */
  padding: 3rem 6.7rem 6rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .each-event-header-wrap {
    margin-bottom: 20px;
    margin-top: 10px;
}
  .wpw-wrap {
    width: 100%;
    /* max-width: 128rem; */
    margin-bottom: 20px;
    /* padding: 0 6.7rem; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .wpw-img {
    /* max-width: 128rem;
    padding: 2rem 6.7rem; */
    margin-bottom: 20px;
  }

  /* Flex column */
  .flex-column {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    border: 1px solid #cccccc;
    border-radius: 3px;
    transition: all .3s ease;
    width: 31.6%;
  }
  .flex-column:hover {
    transform: translateY(-4px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  /* Flex header */
  .flex__header {
    padding: 0;
    border-bottom: 1px solid #ccc;
  }
  .flex__header h2 {
    padding: 0.8rem 1rem;
    margin: 0;
    font-size: 2.1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  /* Flex must read */
  .flex__must-read {
    border-bottom: 1px solid #ccc;
  }
  .flex__must-read .must-read {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0.8rem 1rem;
    background-color: #f4f4f4;
    border-bottom: 1px solid #cccccc;
  }
  .flex__must-read .image {
    height: 120px;
    overflow: hidden;
    position: relative;
    margin: 1rem 1rem 0;
  }
  .flex__must-read img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .flex__must-read h3 {
    font-size: 1.8rem;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    font-weight: 400;
    padding: 1rem 1rem 0.8rem;
  }
  .flex__must-read .text-must-read {
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    font-weight: 300;
    padding: 0 1rem 0.8rem;
  }
  .flex__must-read a.read-more {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #ef7d25;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
    padding: 0 1rem 1.6rem;
    display: inline-block;
  }
  .flex__must-read a.read-more:hover {
    text-decoration: underline;
  }

  /* Flex key takeaway */
  .flex__key-takeaway {
    border-bottom: 1px solid #ccc;
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    /* flex-grow: 6;
    flex-shrink: 1;
    flex-basis: 0%; */
  }
  .flex__key-takeaway ul {
    padding: 0 1rem 0 2.5rem;
    margin: 0;
  }
  .flex__key-takeaway li {
    font-size: 1.4rem;
    font-family: 'Roboto', sans-serif;
    margin: 0.8rem 0 1.6rem;
  }
  .flex__key-takeaway li:last-child {
    margin-bottom: 0;
  }
  .flex__key-takeaway .key-takeaway {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0.8rem;
    background-color: #f4f4f4;
    border-bottom: 1px solid #cccccc;
  }
  @media (max-width: 1023px){
    padding: 6rem;
  }
  @media (max-width: 799px){
    padding: 2rem;
    .flex-column {
      width: 48.7%;
      margin-bottom: 2.6%;
    }
    .flex-column:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 480px){
    padding: 1rem;
    .flex-column {
      width: 100%;
      margin-bottom: 2.6%;
    }
  }
`;

const handleResize = () => {
  // set variables
  const headers = document.querySelectorAll('.flex__header');
  const mustReads = document.querySelectorAll('.flex__must-read');
  const keyTakeaways = document.querySelectorAll('.flex__key-takeaway');
  const quotes = document.querySelectorAll('.flex__quote');

  /*
  * @function sameHeight: calculate max-height and set the value to the elements
  * @params {arr} array of html elements
  * side effect: set the height on the elements in the array with the maximum height
  */
  function sameHeight(arr) {
    let maxHeight = 0;
    const width = window.innerWidth;

    // reset the height:
    arr.forEach((element) => {
      element.style.height = 'inherit';
      return null;
    }
    );

    // if window inner width is more than 480px set the same max width to the elements
    // otherwise for mobile is already reset to inherit
    if (width > 480) {
      // calculate the maxHeight of the elements
      arr.forEach((element) => {
        if (element.offsetHeight > maxHeight) {
          maxHeight = element.offsetHeight;
        }
        return null;
      });

      // set the maxHeight to every elements
      arr.forEach((element) => {
        element.style.height = `${maxHeight}px`;
        return null;
      });
    }
  }

  // function calls with the 4 different array
  sameHeight(headers);
  sameHeight(mustReads);
  sameHeight(keyTakeaways);
  sameHeight(quotes);
};

export default function Template(props) {
  useEffect(() => {
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);
  const {
    data,
    pageContext, // this prop will be injected by the GraphQL query below.
  } = props;

  const { isMember } = pageContext;

  return (
    <>
      <Helmet>
        <title>{data.contentfulEvent.title} follow-up</title>
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:1319836,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `
          }}
        /> */}
      </Helmet>
      <HeaderLogo />
      <HeaderTitle
        title='Event takeaways: Putting words into action'
        logo={data.contentfulEvent.logo.fluid.src}
      />
      <TakeawaysWrapper>
        {data.contentfulEvent && data.contentfulEvent.takeaway && data.contentfulEvent.takeaway.filter(item => !item.cpo).map(takeaway => (
          <div className='flex-column' key={takeaway.id}>
            <div className='flex__header'>
              <h2>{takeaway.title}</h2>
            </div>
            <div className='flex__must-read'>
              <p className='must-read'>Must read PL resource</p>
              {isMember ? (
                  <>
                    <div className='image'>
                      <img
                        className='takeaway__thumbnail'
                        src={takeaway.thumbnail.fluid.src}
                        alt={takeaway.thumbnail.fluid.title}
                      />
                    </div>
                    <h3>{takeaway.resourceTitle}</h3>
                    <p className='text-must-read'>{takeaway.resourceDescription}</p>
                  </>
                ) : (
                  <>
                    <div className='image'>
                      <img
                        className='takeaway__thumbnail'
                        src={takeaway.nonMemberResourceThumbnail.fluid.src}
                        alt={takeaway.nonMemberResourceThumbnail.fluid.title}
                      />
                    </div>
                    <h3>{takeaway.nonMemberResourceTitle}</h3>
                    <p className='text-must-read'>{takeaway.nonMemberResourceDescription}</p>
                  </>
                )}
              <a
                className='read-more'
                href={
                  pageContext.isMember
                    ? takeaway.memberLink
                    : takeaway.nonMemberLink
                }
              >
                READ MORE
              </a>
            </div>
            <div className='flex__key-takeaway'>
              <p className='key-takeaway'>Key actions</p>
              {documentToReactComponents(takeaway.keyTakeaways.json)}
            </div>
            <div className='flex__quote'>
              <KeyStatistic statistic={takeaway.keyStatistic.json} quoteSource={takeaway.quoteSource} />
            </div>
          </div>
        ))}
      </TakeawaysWrapper>
      {pageContext.isMember && <CommunitiesBanner />}
      <NextEvents events={data.allContentfulFutureEvents.edges} />
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
query ($permalink: String!) {
  contentfulEvent(permalink: {eq: $permalink}) {
    title
    logo {
      fluid {
        src
      }
    }
    takeaway {
      id
      title
      cpo
      resourceTitle
      resourceDescription
      nonMemberResourceTitle
      nonMemberResourceDescription
      nonMemberResourceThumbnail {
        id
        title
        fluid(maxWidth: 500) {
          src
        }
      }
      memberLink
      nonMemberLink
      thumbnail {
        id
        title
        fluid(maxWidth: 500) {
          src
        }
      }
      keyTakeaways {
        json
      }
      keyStatistic {
        id
        json
      }
      quoteSource
    }
  }
  allContentfulFutureEvents(sort: {fields: startDate}) {
    edges {
      node {
        id
        eventName
        eventImage {
          file{
            url
          }
        }
        themeOne
        themeTwo
        themeThree
        description
        startDate(formatString: "DD MMMM YYYY")
        endDate(formatString: "DD MMMM YYYY")
        eventLocation
        agendaLink
        active
      }
    }
  }
}
`;


Template.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
    ancestorOrigins: PropTypes.shape({}),
    origin: PropTypes.string,
    protocol: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    port: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.any,
    key: PropTypes.string
  }),
  pageResources: PropTypes.shape({
    page: PropTypes.shape({
      componentChunkName: PropTypes.string,
      jsonName: PropTypes.string,
      path: PropTypes.string
    })
  }),
  data: PropTypes.shape({
    contentfulEvent: PropTypes.shape({
      title: PropTypes.string,
      logo: PropTypes.shape({
        fluid: PropTypes.shape({ src: PropTypes.string })
      }),
      takeaway: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          resourceTitle: PropTypes.string,
          resourceDescription: PropTypes.string,
          memberLink: PropTypes.string,
          nonMemberLink: PropTypes.string,
          thumbnail: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            fluid: PropTypes.shape({ src: PropTypes.string })
          }),
          keyTakeaways: PropTypes.shape({
            json: PropTypes.shape({
              data: PropTypes.shape({}),
              content: PropTypes.arrayOf(
                PropTypes.shape({
                  data: PropTypes.shape({}),
                  content: PropTypes.arrayOf(
                    PropTypes.shape({
                      data: PropTypes.shape({}),
                      marks: PropTypes.array,
                      value: PropTypes.string,
                      nodeType: PropTypes.string
                    })
                  ),
                  nodeType: PropTypes.string
                })
              ),
              nodeType: PropTypes.string
            })
          }),
          keyStatistic: PropTypes.shape({
            id: PropTypes.string,
            json: PropTypes.shape({
              data: PropTypes.shape({}),
              content: PropTypes.arrayOf(
                PropTypes.shape({
                  data: PropTypes.shape({}),
                  content: PropTypes.arrayOf(
                    PropTypes.shape({
                      data: PropTypes.shape({}),
                      marks: PropTypes.array,
                      value: PropTypes.string,
                      nodeType: PropTypes.string
                    })
                  ),
                  nodeType: PropTypes.string
                })
              ),
              nodeType: PropTypes.string
            })
          }),
          quoteSource: PropTypes.string
        })
      )
    }),
    allContentfulFutureEvents: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            eventName: PropTypes.string,
            eventImage: PropTypes.shape({
              file: PropTypes.shape({ url: PropTypes.string })
            }),
            themeOne: PropTypes.string,
            themeTwo: PropTypes.string,
            themeThree: PropTypes.string,
            description: PropTypes.string,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            eventLocation: PropTypes.string,
            agendaLink: PropTypes.string,
            active: PropTypes.bool
          })
        })
      )
    })
  }),
  pageContext: PropTypes.shape({
    isCreatedByStatefulCreatePages: PropTypes.bool,
    permalink: PropTypes.string,
    isMember: PropTypes.bool,
    isCPO: PropTypes.bool
  })
};
