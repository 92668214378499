import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 3px;
  width: 31.6%;
  border: 1px solid #cccccc;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding-bottom: 4rem;
  position: relative;
  margin-right: 2.6%;

  :hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
  }
  :nth-child(3) {
    margin-right: 0;
  }
  .card__img-wrapper {
    height: 200px;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .card__img {
    width: 580px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .card__title {
    font-size: 2.1rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }
  .card__desc {
    font-size: 1.6rem;
    margin-top: 0;
    margin-bottom: 2.5rem;
    font-weight: 300;
  }
  .card__event-themes {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 1.6rem;
  }
  .card__content {
    padding: 0 0 0 25px;
  }
  .card__content li {
    font-size: 1.4rem;
    font-weight: 300;
  }
  a.card__link {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ef7d25;
    text-decoration: none;
    position: absolute;
    left: 1rem;
    bottom: 1.5rem;
  }
  a.card__link:hover {
    text-decoration: underline;
  }
  time,
  .card__location {
    font-size: 1.4rem;
    margin-top: 0.6rem;
    img {
      vertical-align: top;
      margin-right: 3px;
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 799px) {
    width: 48.7%;
    margin-bottom: 2.6%;
    :nth-child(2) {
      margin-right: 0;
    }
    :nth-child(3) {
      margin-right: 2.6%;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0;
    :nth-child(3) {
      margin-right: 0;
    }
  }
`;

const CardBody = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0 auto;
`;

const SingleEvent = (props) => {
  const { event } = props;
  return (
    <Card>
      <div className='card__img-wrapper'>
        <img
          className='card__img'
          src={event.eventImage.file.url}
          alt={event.title}
        />
      </div>
      <CardBody>
        <h2 className='card__title'>{event.eventName}</h2>
        <p className='card__desc'>{event.description}</p>

        <time>
          {' '}
          <img
            src='https://www.procurementleaders.com/AcuCustom/Sitename/Icon/Item/icon-calendar-2019.svg'
            alt='icon location'
          />{' '}
          {event.startDate} - {event.endDate}
        </time>
        <div className='card__location'>
          <img
            src='https://www.procurementleaders.com/AcuCustom/Sitename/Icon/Item/icon-location-2019.svg'
            alt='icon location'
          />{' '}
          {event.eventLocation}
        </div>

        <p className='card__event-themes'>Event themes include:</p>
        <ul className='card__content'>
          <li>{event.themeOne}</li>
          <li>{event.themeTwo}</li>
          <li>{event.themeThree}</li>
          {/* {event.themesList.map(content => <li key={`test-${event.id}`}>{content}</li>)} */}
        </ul>

        <a className='card__link' href={event.agendaLink} title='Find out more'>
          Find out more
        </a>
      </CardBody>
    </Card>
  );
};

SingleEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    eventName: PropTypes.string,
    eventImage: PropTypes.shape({
      file: PropTypes.shape({ url: PropTypes.string }),
    }),
    themeOne: PropTypes.string,
    themeTwo: PropTypes.string,
    themeThree: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    eventLocation: PropTypes.string,
    agendaLink: PropTypes.string,
    active: PropTypes.bool,
  }),
};

export default SingleEvent;
